import React from 'react';

function PronostixNoBorder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M249.8 64.8c102.2 0 185 82.8 185 185s-82.8 185-185 185-185-82.8-185-185 82.9-185 185-185zM156.2 358l59.1-22.7 10-51.6H249.2c13.4-.1 26.2-1.7 38.5-4.9 12.7-3.3 23.8-8.5 33.3-15.8 9.6-7.2 17.2-16.6 22.9-28.2 5.7-11.6 8.5-25.6 8.5-42.2 0-11.4-2.1-21.1-6.2-29.1s-9.7-14.4-16.8-19.3c-7.1-4.9-15.4-8.5-25-10.7s-19.8-3.3-30.8-3.3h-73.2l-19 97.4.2-1.2c6.8-6.9 16-13.8 27.8-20.7l.9-.5c12.2-7 22.5-11.2 30.9-12.5l-.4.1c4-12.7 15.6-22 29.3-22 17 0 30.8 12.2 30.8 31.5S287.4 234 270.4 234c-10.4 0-19.5-5.2-25.1-13.3-13.5 4.1-28.2 13-44 26.8-24.2 21.1-34.2 55.3-36.5 66.6-.4 1.9-.9 4.4-1.5 7.3v.2l-7.1 36.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default PronostixNoBorder;
