import React from 'react';
import PronostixNoBorder from '../../assets/icons/PronostixNoBorder';
import clsx from 'clsx';

const variants = {
  full: 'w-24 h-24',
  medium: 'w-16 h-16',
  small: 'w-10 h-10',
} as const;

interface LoaderProps {
  variant?: keyof typeof variants;
}

export const Loader: React.FC<LoaderProps> = ({ variant = 'medium' }) => {
  const classNames = clsx('relative flex items-center justify-center', variants[variant]);
  return (
    <div className={`flex justify-center items-center ${variant === 'small' ? 'p-0' : 'p-4'}`}>
      <div className={classNames}>
        <div className="absolute w-full h-full animate-spin rounded-full bg-transparent border-t-2 border-r-2 border-blue-500" />
        <PronostixNoBorder className="text-blue-500 w-full h-full" />
      </div>
    </div>
  );
};
